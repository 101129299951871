import Link from "next/link";

const FooterList = ({ footer }) => {
  return (
    <div className="text-xs text-grey-200">
      <h4 className="mb-4 whitespace-normal">
        <Link
          aria-label={footer.title}
          href={footer.path}
          className="font-bold underline decoration-red"
        >
          {footer.title}
        </Link>
      </h4>
      <ul>
        {footer?.childItems &&
          Object.entries(footer.childItems).map(([key, item]) => (
            <li key={key} className="mb-4 whitespace-normal">
              <Link aria-label={item} href={item}>
                {key}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default FooterList;

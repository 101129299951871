const labels = {
  ["404HeadTitle"]: "404 Not Found",
  ["404TitleOops"]: "Oops!",
  ["404TitleNotFound"]: "404 Page not found.",
  ["404Subtitle"]: "The page you’re looking for doesn’t exist.",
  ["404CTA"]: "Take Me Home",
  ["404ShortcodeLabel"]: "While you’re here, grab a great bonus",
  ["404Message"]: "That page can’t be found.",
  ["500HeadTitle"]: "500 Internal Server Error",
  ["500Title"]: "500.",
  ["500Message"]: "Internal Server Error.",
  affiliateAllReview: "Best Online Sportsbooks Reviews",
  affiliateCTA: "Claim Offer",
  affiliatesReview: "Read Review",
  affiliateTitlePostfix: "On Any Sport",
  authorsEvergreensTitle: "Become an expert with our Betting Guides",
  authorsPostsTitle: "Latest betting news",
  authorPageTitlePrefix: "Author:",
  articleCTATitle: "Boost Your Winnings Now!",
  articleCTAText: "Claim 50% Match Up To $1,000",
  articleCTABonus: "+ $10 Free Cash Bonus",
  articleCTAPromoCodePre: "(use promo code",
  articleCTACode: "BNEWS",
  articleCTAPromoCodePost: ")",
  articleCTaTimeLimited: "Exclusive BetOnline Offer - Limited Time",
  bestBetsTitle: "Best Bets Today",
  cardGridSeeAllPrefix: "See all",
  cardGridLoadMore: "Load More Articles",
  consensusOverUnder: "O/U",
  dynamicTitle: " is LIVE on ",
  freeBettingPicksHeader: "Free Betting Picks",
  freeBettingPicksNoGames: "Get ready for upcoming games by checking our ",
  freeBettingGuide: "betting guide",
  footerAboutUsLink: "About Us",
  footerCopyrightStartYear: "2019 -",
  footerCopyrightMobilePrefix: "©",
  footerCopyrightMobilePostfix: "- Betting News",
  footerCopyrightDesktopPrefix: "Copyright ©",
  footerCopyrightDesktopPostfix: "Betting News All Rights Reserved.",
  footerDisclaimer:
    "If you or someone you know has a gambling problem, crisis counseling, and referral services can be accessed by calling 1-800-GAMBLER (1-800-426-2537) (USA), 1-800-9-WITH-IT (IN only), 1-800-NEXT-STEP (AZ only), 1-800-BETS-OFF (IA only) or 1-800-522-4700 (CO only).",
  footerSocialPrefix: "Follow Us",
  footerPrivacyPolicyLink: "Privacy Policy",
  footerRegulatedAreasPrefix: "Regulated in ",
  footerFollowSocialPrefix: "Join The Betting News Community",
  footerLogoText: `
    Betting News is your trusted source for betting picks and up to date news and stats on the NFL, MLB, NHL and many other sports.
    We analyze every game to help you find the best bets and best odds to wager on today’s games.
    We also review online gambling websites to help you find the best sportsbooks and casino sites to play at.
    We are Here to Help You Make Informed Betting Decisions and help players have more fun and more wins when gambling online.
  `,
  footerGambleAware:
    "Content reserved for readers of 18+ years of age or legal majority in their jurisdiction.",
  footerResponsibleGaming: "Responsible Gaming.",
  liveStatus: "Live",
  gameCardNoPicks: "No picks available",
  gameCardSiteFixPostfix: " expert picks for %awayTeam vs %homeTeam »",
  homepageCategoriesCard: "More Betting News",
  homepageLiveStream: "Daily Streams on Twitch & YouTube",
  homepageMoreArticles: "Sports Betting Analysis",
  homepageNewsTitle: "Sports Betting News",
  homepagePicksTitle: "Today’s Matchups & Picks",
  homepageSiteDescription:
    "We are your go-to source for sports betting information: upcoming matchups, expert picks, top- rated sportsbooks, analysis, and more.",
  homepageSubtitle: "Come for the Winners & Stay for the Vibes!",
  homepageTitle: "Welcome to Betting News",
  latestNewsBlockHeader: "More %s News on Betting News",
  leaguePageTitlePostfix: "Betting",
  matchupLoadingTitle: "Betting News: Loading",
  matchupNotFound: "Not Found!",
  matchupOddsTableHeader: "Betting Odds",
  matchupOtheGames: "Matchups",
  matchupPicksTableExperts: "Experts",
  matchupPicksTableHeader: "Picks Sources",
  matchupPicksTablePublicBet: "Public Bet %",
  matchupPostArticleCTA: "Read our full analysis",
  matchupStatsRecordsHeader: "Betting Records",
  matchupStatsSummaryHeader: "Stats Summary",
  matchupStatsTeamHeader: "Team Stats",
  matchupTopicRelatedMore: "More",
  matchupTopicRelatedNews: "Articles",
  matchupTrendsHeader: "Last 5 Games",
  mobileNavMenu: "Menu",
  mobileNavPicks: "Picks",
  navBarSearchTitle: "Search",
  newsletterFormTitle: "The Weekender’s Newsletter",
  newsletterFormConfirmationTitle:
    "Thank you for subscribing to our newsletter!",
  newsletterFormConfirmationText:
    "Please check your email to confirm your subscription.",
  newsletterFormConfirmationButton: "Take Me Home",
  newsletterFormSubscribeButton: "Sign Me Up!",
  newsletterCTA: "Get FREE Picks",
  newsletterText: "Expert FREE Picks Every Saturday",
  newsletterTitle: "Signup for our Weekly Newsletter",
  oddsPageBetPrompt: "Bet Now",
  oddsPageNoGames: "No games scheduled for today",
  oddsPageNoOdds: "Sorry, nothing available at the moment",
  picksPageNoGames: "No games scheduled for today",
  picksPageNoOdds: "Sorry, nothing available at the moment",
  picksPageTitlePostfix: "Picks",
  picksSiteCTA: "Free Picks & Game stats",
  picksCTAPrefix: "Get",
  picksCTAPostFix: "by Betting at",
  quickCapCTAPrefix: "Best Bet at",
  picksSiteCountPostfix: "expert picks »",
  picksNBATopText: [
    "Betting News has the whole NBA season covered with analysis and predictions on all the games, every day. Youlll find here the most up to date NBA Odds & NBA Picks for tonight's games.",
    {
      text: "Whether you are looking to bet on",
      links: [
        { linkText: "NBA Moneyline", linkHref: "/nba/tips/" },
        {
          text: ", ",
          linkText: "Spreads",
          linkHref: "/guides/moneyline-vs-spread-betting/",
        },
      ],
      afterText:
        " or the Over/Under, you will find the best basketball picks right at your fingertips.",
    },
    "Click on any matchup below for best odds comparison on tonight's NBA games, team statistics head-to-head, and expert betting picks.",
    {
      text: "For a deeper analysis on tonight's games, check our NBA Best Bets Tonight article or our many other articles analyzing NBA games. Every day, we crunch the numbers, analyze the data, and provide you free NBA picks from our experienced team. We give you predictions and best bets on all NBA games, including prop bets advice and parlays ideas.",
      linkText: "NBA Best Bets Tonight article",
      linkHref: "/articles/nba/nba-best-bets-tonight/",
    },
    "Let's hit the Hardwood!",
  ],
  quickCapCTAPostfix: "»",
  gameStartTimeDisplayNextGame: "Next game:",
  gameStartTimeDisplayTodayGame: "Today",
  gameStartTimeDisplayTomorrowGame: "Tomorrow",
  offseasonTitle: "The %league is in the ",
  offseasonWord: "offseason",
  offseasonText:
    "Stay connected with us through our latest updates, or you may check out check out other thrilling games and claim exciting bonuses today - Don't miss out!",
  offseasonUpdatesCTA: "LATEST UPDATES",
  offseasonSportsbooksCTA: "SEE OUR BEST SPORTSBOOKS",
  postMatchUpHeader: "See Picks & Statistics For The Game",
  postMatchUpBottom: "See our Matchup - Free Picks & Stats",
  rightMenuSportGuide: "Sports Betting Guides",
  rivalryMatchupLink: "View Matchup",
  searchButton: "Search",
  searchNoResults: "Sorry! We can't find what you're looking for.",
  searchPageDescriptionPrefix: "Search Results for",
  searchPageTitlePrefix: "Search Results:",
  searchPlaceholder: "Enter your search term",
  searchResultsPrefix: "Search Results:",
  sportsbookPromosHeader: "Sportsbook Promos",
  sportsbookPromoShortcodesHeader: "Best Online Sportsbooks",
  stagingEnvironmentWarning: "YOU ARE ON THE STAGING ENVIRONMENT",
  storeLabel: "Store",
  authorInfoWrittenBy: "Written by",
  authorInfoPublished: "Published",
  authorInfoLastUpdated: "Last Updated",
  authorInfoReadTime: "Read Time",
  articleDisclaimer:
    "This site contains commercial content. We may be compensated for the links provided on this page. The content on this page is for informational purposes only. Betting News makes no representation or warranty as to the accuracy of the information given or the outcome of any game or event.",
};

export default labels;

import { list as svgList } from "./list";
import SvgIcon from "./SvgIcon";

const Icon = ({ name, ...props }) => {
  const icons = {
    articlesCategory: "newspaper",
    boxingCategory: "boxingGlove",
    dfsCategory: "basketballHoop",
    golfCategory: "golfClub",
    horsesCategory: "horseHead",
    industryCategory: "newspaper",
    wnbaCategory: "basketballHoop",
    mmaCategory: "boxingGlove",
    nbaCategory: "basketballHoop",
    nflCategory: "footballHelmet",
    nhlCategory: "hockeySticks",
    regulationCategory: "balanceScale",
    showsCategory: "tvRetro",
    soccerCategory: "futbol",
    tennisCategory: "tennisBall",
    nascarCategory: "steeringWheel",
    mlbCategory: "mlb",
    f1Category: "steeringWheel",
    horseracingCategory: "horseHead",
    mobileNavClose: "timesCircle",
    clock: "clock",
    mobileNavMenu: "bars",
    mobileNavPicks: "bolt",
    navHorseRacing: "horseHead",
    bettingindustryCategory: "newspaper",
    miscellaneoussportsCategory: "trophy",
    uefachampionsleagueCategory: "uefachampionsleague",
    ncaabCategory: "ncaab",
    ncaafCategory: "ncaaf",
    navOdds: "table",
    navPicks: "checkCircle",
    navGamblingSites: "sackDollar",
    navSoccer: "futbol",
    navGuides: "guides",
    navSportsbooks: "chart",

    matchUpBolt: "bolt",
    matchUpArrow: "arrowAltCircleRight",
    search: "search",

    nflSelect: "footballHelmet",
    nbaSelect: "basketballHoop",
    wnbaSelect: "basketballHoop",
    nhlSelect: "hockeySticks",
    mlbSelect: "mlb",
    soccerSelect: "futbol",
    allSelect: "bolt",
    uefachampionsleagueSelect: "uefachampionsleague",
    ncaabSelect: "ncaab",
    ncaafSelect: "ncaaf",

    kentuckyGuide: "horseHead",
    nbaGuide: "basketballHoop",
    ncaafGuide: "footballHelmet",
    nflGuide: "footballHelmet",
    nhlGuide: "hockeySticks",
    wnbaGuide: "basketballHoop",
    mlbGuide: "mlb",

    discord: "discord",
    twitch: "twitch",
    twitter: "xTwitter",
    tiktok: "tiktok",
    spotify: "spotify",
    facebook: "facebook",
    instagram: "instagram",
    youtube: "youtube",

    mexicoligamxCategory: "soccerLigaMx",
    mexicoligamxSelect: "soccerLigaMx",
  };

  if (svgList.includes(icons[name] ?? name)) {
    return <SvgIcon {...props} iconName={icons[name] ?? name} />;
  }

  return null;
};

export default Icon;

import dayjs from "dayjs";
import * as NextJSHead from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { BN_URL, IS_PROD } from "@/config/constants";
import labels from "@/config/labels";
import { useAppContext } from "@/hooks/useAppContext";
import { useLiveShow } from "@/hooks/useLiveShow";
import {
  formatOrganizationSchema,
  removeDuplicateMetas,
  replacePrivateBaseURLFromFullHead,
} from "@/utilities/seo";

const Head = ({ isHome, seo, matchup, imagesToPreload }) => {
  const router = useRouter();
  const { shows } = useAppContext();
  const isMatchup = router?.pathname?.includes("matchup");
  const isArticle = router?.pathname?.includes("/articles/");
  const isHomePage = router?.pathname === "/";
  const canonical = BN_URL + router?.asPath ?? "";
  const liveShow = useLiveShow(shows);
  const formattedSeo = replacePrivateBaseURLFromFullHead(seo);

  const [dynamicTitle, setDynamicTitle] = useState(formattedSeo?.title);

  useEffect(() => {
    if (!liveShow) {
      setDynamicTitle(formattedSeo?.title);
      return;
    }

    const showTitle = liveShow?.active
      ? `🔥 ${liveShow.showName} ${labels.dynamicTitle} ${liveShow?.banner[1]}! `
      : "";
    let scrollIndex = 0;

    const updateTitle = () => {
      const scrollingTitle =
        showTitle.substring(scrollIndex) + showTitle.substring(0, scrollIndex);
      setDynamicTitle(scrollingTitle);
      scrollIndex = (scrollIndex + 1) % showTitle.length;
    };

    const intervalId = setInterval(updateTitle, 200);

    return () => clearInterval(intervalId);
  }, [liveShow, formattedSeo?.title]);

  let robots = IS_PROD ? (
    <meta
      name="robots"
      content={`${formattedSeo?.robots ?? "index, follow"}`}
    />
  ) : (
    <meta name="robots" content="noindex, nofollow" />
  );

  // If the game is more than 7 days old, set noindex, nofollow
  if (
    router?.pathname === "/[path]/matchup/[game_key]" &&
    dayjs().diff(matchup?.startTime, "day") > 7
  ) {
    robots = <meta name="robots" content="noindex, nofollow" />;
  }

  return (
    <NextJSHead>
      {imagesToPreload?.length > 0
        ? imagesToPreload.map((imageData, index) => (
            <link
              key={`image-to-preload-${index}`}
              rel="preload"
              as="image"
              href={imageData?.href || ""}
              imageSizes={imageData?.sizes || ""}
              imageSrcSet={imageData?.srcSet || ""}
            />
          ))
        : null}
      {robots}
      {formattedSeo?.title && <title>{dynamicTitle}</title>}
      {formattedSeo?.metaDesc && (
        <meta name="description" content={formattedSeo?.metaDesc} />
      )}
      {canonical && (
        <link rel="canonical" href={canonical ?? ""} key="canonical" />
      )}
      {/* Opengraph */}
      <meta property="og:locale" content="en_US" />
      {formattedSeo?.ogType && (
        <meta property="og:type" content={formattedSeo?.ogType} />
      )}
      {formattedSeo?.title && (
        <meta property="og:title" content={formattedSeo?.title} />
      )}
      {formattedSeo?.metaDesc && (
        <meta property="og:description" content={formattedSeo?.metaDesc} />
      )}
      {canonical && <meta property="og:url" content={canonical} />}
      <meta property="og:site_name" content="Betting News" />
      {formattedSeo?.publishedTime && (
        <meta
          property="article:published_time"
          content={formattedSeo.publishedTime}
        />
      )}
      {formattedSeo?.modifiedTime && (
        <meta
          property="article:modified_time"
          content={formattedSeo.modifiedTime}
        />
      )}
      {formattedSeo?.ogImage?.src && (
        <meta property="og:image" content={formattedSeo?.ogImage?.src} />
      )}
      {formattedSeo?.ogImage?.width && (
        <meta property="og:image:width" content={formattedSeo.ogImage.width} />
      )}
      {formattedSeo?.ogImage?.height && (
        <meta
          property="og:image:height"
          content={formattedSeo.ogImage.height}
        />
      )}
      {formattedSeo?.ogImage?.type && (
        <meta property="og:image:type" content={formattedSeo.ogImage.type} />
      )}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@YourBettingsNews" />
      <meta
        name="twitter:creator"
        content={formattedSeo?.author?.twitterUsername || "@YourBettingsNews"}
      />
      {formattedSeo?.title && (
        <meta name="twitter:title" content={formattedSeo?.title} />
      )}
      {formattedSeo?.metaDesc && (
        <meta name="twitter:description" content={formattedSeo?.metaDesc} />
      )}
      {!isMatchup && !isHomePage && canonical && (
        <meta name="twitter:url" content={canonical} />
      )}
      {formattedSeo?.ogImage?.src && (
        <>
          <meta name="twitter:image" content={formattedSeo.ogImage.src} />
          {formattedSeo?.title && (
            <meta name="twitter:image:alt" content={formattedSeo.title} />
          )}
        </>
      )}
      {/* Author */}
      {isArticle && formattedSeo?.author?.name && (
        <meta name="author" content={formattedSeo.author.name} />
      )}

      {formattedSeo?.schema && (
        <script
          id="schema-org-json-ld"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: formattedSeo.schema,
          }}
        />
      )}
      {isMatchup && (
        <>
          <meta property="og:type" content="article" />
          <meta property="og:image:type" content="image/png" />
          <meta
            property="og:image"
            content={`https://www.bettingnews.com/_next/image/?url=${encodeURIComponent(matchup ? matchup[matchup?.winnerSide || ""]?.logo || "" : "")}&w=256&q=100`}
          />
          <meta property="og:image:width" content="256" />
          <meta property="og:image:height" content="256" />
          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:image"
            content={`https://www.bettingnews.com/_next/image/?url=${encodeURIComponent(matchup ? matchup[matchup?.winnerSide || ""]?.logo || "" : "")}&w=256&q=100`}
          />
        </>
      )}
      {formattedSeo?.faqSchema && (
        <script
          async
          id="faq-schema"
          data-rh="true"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: formattedSeo?.faqSchema,
          }}
        />
      )}
      {formattedSeo?.gamesSchema && (
        <script
          async
          id="games-schema"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: formattedSeo?.gamesSchema,
          }}
        />
      )}
      {formattedSeo && (
        <script
          async
          id="organization-schema"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: formatOrganizationSchema(formattedSeo),
          }}
        />
      )}
      {isArticle && formattedSeo && (
        <script
          async
          id="news-articles-schema"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: formattedSeo?.newsArticleSchema,
          }}
        />
      )}
      {formattedSeo?.expires && (
        <meta httpEquiv="expires" content={formattedSeo?.expires} />
      )}
      {isHome && (
        <meta
          name="google-site-verification"
          content="tfAjxRgoH3CtJNzBflq4K1NccmR4Po3fT_3IMGaVeh8"
          key="google-site-verification"
        />
      )}
      {isHome && (
        <meta
          name="google-site-verification"
          content="tAFGF1MP6JRRPW7JQBXMerrgttTbkPgrHcL1_Hjb3jk"
        />
      )}
      {/*Mailerlite Script - Doesn't work with the Script of nextjs */}
      <script
        async
        src="https://static.mailerlite.com/js/w/webforms.min.js?vd4de52e171e8eb9c47c0c20caf367ddf"
        type="text/javascript"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `function ml_webform_success_6005595(){var r=ml_jQuery||jQuery;r(".ml-subscribe-form-6005595 .row-success").show(),r(".ml-subscribe-form-6005595 .row-form").hide()}`,
        }}
      ></script>
    </NextJSHead>
  );
};

if (typeof window !== "undefined") {
  removeDuplicateMetas();
}

export default Head;

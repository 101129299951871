import { useEffect, useRef, useState } from "react";

import labels from "@/config/labels";

import LiveStreamBanner from "./LiveStreamBanner";

function TwitchEmbedded() {
  const twitchRef = useRef(null);
  const [isOnline, setIsOnline] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (!isClient) return;

    const script = document.createElement("script");
    script.src = "https://player.twitch.tv/js/embed/v1.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      const player = new Twitch.Player(twitchRef.current, {
        channel: "bettingnews",
        parent: ["bettingnews.com", "stagingnext.bettingnews.com"],
        autoplay: true,
        muted: true,
        width: "100%",
        height: 380,
      });

      const handleOnline = () => setIsOnline(true);
      const handleOffline = () => setIsOnline(false);

      player.addEventListener(Twitch.Player.ONLINE, handleOnline);
      player.addEventListener(Twitch.Player.OFFLINE, handleOffline);

      return () => {
        player.removeEventListener(Twitch.Player.ONLINE, handleOnline);
        player.removeEventListener(Twitch.Player.OFFLINE, handleOffline);
        player.destroy();
      };
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [isClient]);

  return (
    <div className="my-8">
      <h2 className="mb-2 font-heading text-lg font-bold leading-[1.15] text-nav-blue sm:text-[1.6rem]">
        {labels.homepageLiveStream}
      </h2>
      {!isOnline && <LiveStreamBanner />}
      <div ref={twitchRef} className={isOnline ? "" : "hidden"}></div>
    </div>
  );
}

export default TwitchEmbedded;

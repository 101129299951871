import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useEffect, useState } from "react";

import { showTime } from "@/utilities/dates";

dayjs.extend(timezone);

export const useLiveShow = (shows) => {
  const [liveShow, setLiveShow] = useState(null);

  useEffect(() => {
    const findLiveShow = () => {
      const userTimeZone = dayjs.tz.guess();
      const now = dayjs().tz(userTimeZone);

      const activeShow = shows?.find(({ showInfo }) => {
        if (!showInfo || !Array.isArray(showInfo.day)) return false;

        const todayIsInSchedule = showInfo.day.includes(now.day().toString());

        if (!todayIsInSchedule) return false;

        const showStartTime = showTime(showInfo.startTime, userTimeZone);
        const showEndTime = showTime(showInfo.endTime, userTimeZone);

        return now.isAfter(showStartTime) && now.isBefore(showEndTime);
      });

      setLiveShow(activeShow?.showInfo ?? null);
    };

    findLiveShow();

    const interval = setInterval(findLiveShow, 60000);
    return () => clearInterval(interval);
  }, [shows]);

  return liveShow;
};

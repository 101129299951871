export const list = [
  "english-premier-leagueSelect",
  "google",
  "liveIcon",
  "marchMadnessGuide",
  "mlb",
  "navMarchMadness2024",
  "navNews",
  "ncaab",
  "ncaaf",
  "premierCategory",
  "uefachampionsleague",
  "usmlsCategory",
  "usmlsSelect",
  "superBowlGuide",
  "arrowAltCircleRight",
  "balanceScale",
  "bars",
  "baseballBall",
  "basketballBall",
  "bolt",
  "chalkboardTeacher",
  "chart",
  "checkCircle",
  "clock",
  "facebook",
  "footballBall",
  "futbol",
  "guides",
  "horseHead",
  "instagram",
  "newspaper",
  "search",
  "spotify",
  "store",
  "table",
  "tiktok",
  "timesCircle",
  "trophy",
  "twitch",
  "youtube",
  "basketballHoop",
  "footballHelmet",
  "hockeySticks",
  "soccerLigaMx",
  "boxingGlove",
  "golfClub",
  "sackDollar",
  "steeringWheel",
  "tennisBall",
  "tvRetro",
  "xTwitter",
  "discord",
];

import Footer from "@/components/footer/Footer";
import Nav from "@/components/nav/Nav";
import { bodyFont, headingFont } from "@/utilities/fonts";

const NavFooterLayout = ({ children, horses, menus, footer }) => {
  return (
    <>
      <main className={`${bodyFont.variable} ${headingFont.variable}`}>
        <Nav horses={horses} menus={menus} />
        {children}
        <Footer footer={footer} />
      </main>
    </>
  );
};

export default NavFooterLayout;

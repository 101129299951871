import { getFooterYear } from "./dates";

export function formatFooter(data) {
  const edges = data?.menuItems?.edges || [];

  const updateYear = (label) => {
    const currentYear = getFooterYear();
    const lastYear = currentYear - 1;

    return label
      .replace("[year]", currentYear)
      .replace("[last_year]", lastYear);
  };

  const buildFooterOptions = (nodes) => {
    return nodes.reduce((acc, edge) => {
      const node = edge?.node;
      if (!node) return acc;
      let { label, path } = node;
      label = updateYear(label);

      acc[label] = path;

      return acc;
    }, {});
  };

  return edges.reduce((acc, edge) => {
    const node = edge?.node;
    if (!node) return acc;

    const title = node.label || "";
    const path = node.path || "";
    const childItems = node.childItems.edges || [];

    acc[title] = {
      title,
      path,
      childItems: buildFooterOptions(childItems),
    };

    return acc;
  }, {});
}

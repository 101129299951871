import {
  BETTING_RECORD_HEADINGS,
  IS_PROD,
  MLB_STANDINGS_HEADINGS,
  NBA_STANDINGS_HEADINGS,
  NCAAF_STANDINGS_HEADINGS,
  NFL_STANDINGS_HEADINGS,
  NHL_STANDINGS_HEADINGS,
} from "./constants";

export const CONSENSUS_PICKS_MENU = {
  all: {
    id: "all",
    icon: "selectAll",
    name: "Upcoming Games",
    noGameUrl: "/guides/",
  },
  nfl: {
    id: "nfl",
    icon: "selectNFL",
    name: "NFL",
    noGameUrl: "/nfl/tips/",
  },
  nba: {
    id: "nba",
    icon: "selectNBA",
    name: "NBA",
    noGameUrl: "/guides/nba-betting-guide-picks-odds-tips/",
  },
  wnba: {
    id: "wnba",
    icon: "wnbaCategory",
    name: "WNBA",
    noGameUrl: "/",
  },
  mlb: {
    id: "mlb",
    icon: "selectMLB",
    name: "MLB",
    noGameUrl: "/mlb/tips/",
  },
  nhl: {
    id: "nhl",
    icon: "selectNHL",
    name: "NHL",
    noGameUrl: "/nhl/how-to-bet-puck-lines-in-the-nhl/",
  },
  ncaaf: {
    id: "ncaaf",
    icon: "selectNCAAF",
    name: "NCAAF",
    noGameUrl: "/ncaaf/tips/",
  },
  ncaab: {
    id: "ncaab",
    icon: "ncaabCategory",
    name: "NCAAB",
    noGameUrl: "/ncaab/tips/",
  },
  soccer: {
    id: "soccer",
    icon: "selectSoccer",
    name: "Soccer",
    noGameUrl: "/",
  },
};

export const FOOTER_EVENTS_MENUS = {
  title: "EVENTS",
  paths: {
    "Kentucky Derby": "/horses/kentucky-derby",
    "World Series": "/mlb/world-series/",
    "Super Bowl": "/nfl/super-bowl",
  },
};

export const FOOTER_REGULATED_AREAS_MENU = {
  AZ: "/legal/arizona",
  CO: "/legal/colorado/",
  CT: "/legal/connecticut/",
  IL: "/legal/illinois/",
  IN: "/legal/indiana/",
  IA: "/legal/iowa/",
  LA: "/legal/louisiana/",
  MI: "/legal/michigan/",
  NJ: "/legal/nj/",
  NY: "/legal/new-york/",
  ON: "/canada/ontario/",
  PA: "/legal/pennsylvania/",
  TN: "/legal/tennessee/",
  VA: "/legal/virginia/",
  WA: "/legal/washington/",
  WY: "/legal/wyoming/",
};

export const HOMEPAGE_CATEGORIES_MENU = {
  boxing: "Boxing",
  golf: "Golf",
  horses: "Horse Racing",
  mlb: "MLB",
  mma: "MMA",
  nba: "NBA",
  ncaab: "NCAAB",
  ncaaf: "NCAAF",
  nfl: "NFL",
  nhl: "NHL",
  regulation: "Regulation",
  soccer: "Soccer",
  tennis: "Tennis",
  f1: "Formula One",
  industry: "Betting Industry",
  nascar: "NASCAR",
  wnba: "WNBA",
};

export const LEAGUE_ODDS_MENU = {
  moneyLine: { title: "Moneyline" },
  pointSpread: { title: "Point Spread" },
  total: { title: "Total" },
};

export const LEAGUE_PICKS_MENU = {
  moneyLine: {
    title: "Moneyline",
    headings: ["Teams", "Best Line", "Consensus", "Public Bet %"],
  },
  pointSpread: {
    title: "Point Spread",
    headings: ["Teams", "Best Spread", "Consensus", "Public Bet %"],
  },
  total: {
    title: "Total",
    headings: ["Teams", "Best Total", "Consensus", "Public Bet %"],
  },
};

export const LEAGUE_STANDINGS_MENUS = {
  mlb: {
    bettingRecord: {
      title: "Betting Record",
      headings: BETTING_RECORD_HEADINGS,
    },
    league: {
      title: "League",
      headings: MLB_STANDINGS_HEADINGS,
    },
    division: {
      title: "Division",
      headings: MLB_STANDINGS_HEADINGS,
    },
  },
  nba: {
    bettingRecord: {
      title: "Betting Record",
      headings: BETTING_RECORD_HEADINGS,
    },
    conference: {
      title: "Conference",
      headings: NBA_STANDINGS_HEADINGS,
    },
    division: {
      title: "Division",
      headings: NBA_STANDINGS_HEADINGS,
    },
  },
  wnba: {
    bettingRecord: {
      title: "Betting Record",
      headings: BETTING_RECORD_HEADINGS,
    },
    conference: {
      title: "Conference",
      headings: NBA_STANDINGS_HEADINGS,
    },
    division: {
      title: "Division",
      headings: NBA_STANDINGS_HEADINGS,
    },
  },
  nfl: {
    bettingRecord: {
      title: "Betting Record",
      headings: BETTING_RECORD_HEADINGS,
    },
    conference: {
      title: "Conference",
      headings: NFL_STANDINGS_HEADINGS,
    },
    division: {
      title: "Division",
      headings: NFL_STANDINGS_HEADINGS,
    },
  },
  nhl: {
    bettingRecord: {
      title: "Betting Record",
      headings: BETTING_RECORD_HEADINGS,
    },
    league: {
      title: "League",
      headings: NHL_STANDINGS_HEADINGS,
    },
    conference: {
      title: "Conference",
      headings: NHL_STANDINGS_HEADINGS,
    },
    division: {
      title: "Division",
      headings: NHL_STANDINGS_HEADINGS,
    },
  },
  ncaaf: {
    bettingRecord: {
      title: "Betting Record",
      headings: BETTING_RECORD_HEADINGS,
    },
    conference: {
      title: "Conference",
      headings: NCAAF_STANDINGS_HEADINGS,
    },
    division: {
      title: "Division",
      headings: NCAAF_STANDINGS_HEADINGS,
    },
  },
  ncaab: {
    bettingRecord: {
      title: "Betting Record",
      headings: BETTING_RECORD_HEADINGS,
    },
    conference: {
      title: "Conference",
      headings: NCAAF_STANDINGS_HEADINGS,
    },
    division: {
      title: "Division",
      headings: NCAAF_STANDINGS_HEADINGS,
    },
  },
};

export const MATCHUP_ODDS_MENU = {
  moneyLine: { title: "Moneyline" },
  pointSpread: { title: "Point Spread" },
  total: { title: "Total" },
};

export const MATCHUP_PICKS_MENU = {
  moneyLine: { title: "Moneyline" },
  pointSpread: { title: "Point Spread" },
  total: { title: "Total" },
  score: { title: "Score" },
};

export const ARTICLE_PREDICTIONS_MENU = {
  pointSpread: { title: "Spread" },
  total: { title: "Total" },
  moneyLine: { title: "Moneyline" },
};

export const NAV_MENUS = {
  News: {
    menuTitle: "News",
    menuOptions: {
      Shows: "/sports-betting-shows/",
      NFL: "/nfl/",
      NBA: "/nba/",
      WNBA: "/wnba/",
      MLB: "/mlb/",
      NHL: "/nhl/",
      NCAAF: "/ncaaf/",
      NCAAB: "/ncaab/",
      F1: "/category/articles/f1/",
      NASCAR: "/category/articles/nascar/",
      "Horse Racing": "/category/articles/horses/",
      MMA: "/category/articles/mma/",
      Boxing: "/category/articles/boxing/",
      Golf: "/category/articles/golf/",
      "Soccer News": {
        league: true,
        menuTitle: "SOCCER",
        path: "/soccer/",
        menuOptions: {
          "English Premier League": "/soccer/?league=english-premier-league",
          "US MLS": "/soccer/?league=mls",
          "UEFA Champions League": "/soccer/?league=uefa-champions-league",
        },
      },
      Tennis: "/category/articles/tennis/",
      "Miscellaneous Sports": "/category/articles/miscellaneous-sports/",
      "Betting Industry": "/category/articles/industry/",
    },
  },
};

export const NCAAF_DIVISIONS_MENU = {
  divisionIA: { name: "Conferences", slug: "I_A" },
  acc: { name: "ACC", slug: "acc" },
  big12: { name: "Big 12", slug: "big_12" },
  bigTen: { name: "Big Ten", slug: "big_ten" },
  sec: { name: "SEC", id: 23, slug: "sec" },
};

export const NCAAB_DIVISIONS_MENU = {
  divisionI: { name: "Conferences", slug: "Division_I" },
  bigTen: { name: "Big 10", slug: "big_ten" },
  bigEast: { name: "Big East", slug: "big_east" },
  sec: { name: "Southeastern Conference", id: 23, slug: "sec" },
  pac12: { name: "Pac 12", slug: "pac_12" },
  acc: { name: "Atlantic Coast", slug: "acc" },
  mountainWest: { name: "Mountain West", slug: "mountain_west" },
  american: { name: "American Athletic", slug: "american" },
  wcc: { name: "West Coast", slug: "wcc" },
  cusa: { name: "Conference USA", slug: "cusa" },
};

export const SOCCER_MENU = {
  soccer: { name: "All Leagues", slug: "soccer" }, // used this slug while other league are integrated
  mls: { name: "US MLS", slug: "mls" },
  "english-premier-league": {
    name: "English Premier League",
    slug: "english-premier-league",
  },
  "liga-mx": { name: "Mexico Liga MX", slug: "liga-mx" },
  "uefa-champions-league": {
    name: "UEFA Champions League",
    slug: "uefa-champions-league",
  },
};

export const SPORTS_MENU = {
  soccer: { name: "All Leagues", slug: "soccer" },
  mls: { name: "US MLS", slug: "mls" },
  "english-premier-league": {
    name: "English Premier League",
    slug: "english-premier-league",
  },
  // "liga-mx": { name: "Mexico Liga MX", slug: "liga-mx" }, * NO POSTS IN THIS LEAGUE YET - HIDDEN UNTIL FURTHER NOTICE *
  "uefa-champions-league": {
    name: "UEFA Champions League",
    slug: "uefa-champions-league",
  },
};

export const SIDE_MENU = {
  nhl: {
    id: "nhl",
    icon: "guideNHL",
    name: "NHL Betting Guide",
    url: "/guides/nhl-betting-guide/",
  },
  nfl: {
    id: "nfl",
    icon: "guideNFL",
    name: "NFL Betting Guide",
    url: "/nfl-betting-guide/",
  },
  nba: {
    id: "nba",
    icon: "guideNBA",
    name: "NBA Betting Guide",
    url: "/guides/nba-betting-guide/",
  },
  mlb: {
    id: "mlb",
    icon: "mlbGuide",
    name: "MLB Betting Guide",
    url: "/mlb/tips/",
  },
  wnba: {
    id: "wnba",
    icon: "guideWNBA",
    name: "WNBA  Betting Guide",
    url: "/guides/wnba-betting-guide/",
  },
  ncaaf: {
    id: "ncaaf",
    icon: "guideNCAAF",
    name: "College Football Betting Guide",
    url: "/guides/college-football-betting-guide/",
  },
  kentucky: {
    id: "kentucky",
    icon: "guideKentucky",
    name: "Kentucky Derby Betting Guide",
    url: "/best-horse-racing-betting-sites/kentucky-derby/",
  },
  SuperBowl: {
    id: "superBowl",
    icon: "guideSuperBowl",
    name: "Super Bowl Betting Guide",
    url: "/nfl/super-bowl/",
  },
  MarchMadness: {
    id: "marchMadness",
    icon: "guideMarchMadness",
    name: "March Madness Betting Guide",
    url: "/march-madness/",
  },
};

export const PAGES_TAGS = [
  "bestBets",
  "nfl",
  "nba",
  "mlb",
  "nhl",
  "ncaaf",
  "ncaab",
  "soccer",
  "wnba",
];

export const LEAGUE_SEASONS = {
  nfl: [
    { name: "2021", slug: "2021" },
    { name: "2022", slug: "2022" },
    { name: "2023", slug: "2023" },
    { name: "2024", slug: "2024" },
  ],
  mlb: [
    { name: "2023", slug: "2023" },
    { name: "2024", slug: "2024" },
  ],
  nhl: [
    { name: "2020-2021", slug: "20202021" },
    { name: "2021-2022", slug: "20212022" },
    { name: "2022-2023", slug: "20222023" },
    { name: "2023-2024", slug: "20232024" },
    { name: "2024-2025", slug: "20242025" },
  ],
  nba: [
    { name: "2020-2021", slug: "2020-2021" },
    { name: "2021-2022", slug: "2021-2022" },
    { name: "2022-2023", slug: "2022-2023" },
    { name: "2023-2024", slug: "2023-2024" },
  ],
  wnba: [{ name: "2024", slug: "2024" }],
  ncaaf: [
    { name: "2023", slug: "2023" },
    { name: "2024", slug: "2024" },
  ],
  ncaab: [
    { name: "2023", slug: "2023" },
    { name: "2024", slug: "2024" },
    { name: "2025", slug: "2025" },
  ],
};

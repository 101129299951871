import { useCallback, useState } from "react";

import {
  HERO_CARD_GRID_NUMBER_OF_ITEMS_TO_INCREMENT,
  HOMEPAGE_HERO_CARD_GRID_NUMBER_OF_INITIAL_VISIBLE_ITEMS,
} from "@/config/constants";
import labels from "@/config/labels";
import useScreenWidth from "@/hooks/useScreenWidth";

import CardGrid from "./CardGrid";
import Card from "./components/Card";

const HomepageHeroCardGrid = ({ children, items }) => {
  const [numberOfVisibleItems, setNumberOfVisibleItems] = useState(
    HOMEPAGE_HERO_CARD_GRID_NUMBER_OF_INITIAL_VISIBLE_ITEMS
  );

  // Increase number of visible items by constant
  const onShowMoreItems = useCallback(() => {
    setNumberOfVisibleItems(
      (numberOfItems) =>
        numberOfItems + HERO_CARD_GRID_NUMBER_OF_ITEMS_TO_INCREMENT
    );
  }, []);

  // Split posts into groups so that child components can be inserted after the initial 3 posts
  const visibleItems = items?.slice(0, numberOfVisibleItems);
  const firstItemMobile = visibleItems?.[0] || null;
  const firstRowMobile = visibleItems?.slice(1, 3) || [];
  const remainingItemsMobile = visibleItems?.slice(3) || [];

  const firstRowDesktop = visibleItems?.slice(0, 2) || [];
  const secondRowDesktop = visibleItems?.slice(2, 4) || [];
  const remainingItemsDesktop = visibleItems?.slice(4) || [];

  const screenWidth = useScreenWidth();
  const isMobile = screenWidth <= 991;

  return (
    <div>
      {isMobile ? (
        <div>
          {firstItemMobile && <Card isHeroCard item={firstItemMobile} />}
          <div className="lp:gap-6 my-6 grid grid-cols-2 gap-2">
            {firstRowMobile.map((item, index) => (
              <Card key={index} item={item} firstRow={index >= 0} />
            ))}
          </div>
        </div>
      ) : (
        <div className="lp:gap-6 my-6 grid grid-cols-2 gap-2">
          {firstRowDesktop.map((item, index) => (
            <Card key={index} item={item} />
          ))}
          {secondRowDesktop.map((item, index) => (
            <Card key={index} item={item} />
          ))}
        </div>
      )}
      {children}
      <div className="mt-6">
        <h2 className="mb-2 font-heading text-lg font-bold leading-[1.15] text-nav-blue sm:text-[1.6rem]">
          {labels.homepageMoreArticles}
        </h2>
        <CardGrid
          items={isMobile ? remainingItemsMobile : remainingItemsDesktop}
        />
      </div>
      <div>
        {items?.length > numberOfVisibleItems && (
          <button
            aria-label={labels.cardGridLoadMore}
            className="mx-auto mt-8 flex rounded-full bg-blue px-5 py-3 font-heading text-sm font-medium text-white hover:bg-dark-blue"
            onClick={onShowMoreItems}
          >
            {labels.cardGridLoadMore}
          </button>
        )}
      </div>
    </div>
  );
};

export default HomepageHeroCardGrid;

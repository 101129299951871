import dynamic from "next/dynamic";
import { useEffect } from "react";

const Script = dynamic(() => import("next/script"), {
  ssr: false,
});

export function formatCTASportbooks404Shortcode(data) {
  if (!data?.affiliate) {
    return "";
  }
  return data?.affiliate?.affiliateInfo?.shortcode;
}

// link onLoad functions were not firing as expected, so this is in place
// for not as a workaround.
export function useActivateShortCodeStyleSheets() {
  useEffect(() => {
    const shortCodeStyleSheet = document?.getElementById("su-shortcodes-css");
    if (shortCodeStyleSheet) {
      shortCodeStyleSheet.rel = "stylesheet";
    }
    const shortCodeIconStyleSheet = document?.getElementById(
      "su-shortcodes-icons-css"
    );
    if (shortCodeIconStyleSheet) {
      shortCodeIconStyleSheet.rel = "stylesheet";
    }
  }, []);
}

const ShortCodes = () => {
  useActivateShortCodeStyleSheets();

  return (
    <>
      <Script
        src="https://code.jquery.com/jquery-3.6.0.min.js"
        integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4="
        crossOrigin="anonymous"
        strategy="lazyOnload"
        onLoad={() => {
          // A work around to sychronously load jQuery and the shortcodes
          // script without blocking any application rendering
          const shortCodesScript = document?.createElement("script");
          shortCodesScript.id = "su-shortcodes-js";
          shortCodesScript.type = "text/javascript";
          shortCodesScript.src =
            "https://d95f0g9zbpzcu.cloudfront.net/short-codes-wp.js";
          document?.body?.appendChild(shortCodesScript);
        }}
      />
      <link
        rel="preload"
        as="style"
        id="su-shortcodes-icons-css"
        href="https://d95f0g9zbpzcu.cloudfront.net/short-codes.css"
        type="text/css"
        media="all"
      />
      <link
        rel="preload"
        as="style"
        id="su-shortcodes-css"
        href="https://d95f0g9zbpzcu.cloudfront.net/short-codes-icons.css"
        type="text/css"
        media="all"
      />
    </>
  );
};

export default ShortCodes;

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

import { useAppContext } from "@/hooks/useAppContext";
import { useLiveShow } from "@/hooks/useLiveShow";

dayjs.extend(timezone);

const Ticker = () => {
  const router = useRouter();
  const { shows } = useAppContext();
  const liveShow = useLiveShow(shows);

  if (!liveShow) return null;

  const isHomepage = router.pathname === "/";
  const isShowActive = liveShow?.active;

  return (
    <>
      {isShowActive && (
        <div className="mb-4 rounded-md bg-gradient-to-r from-[#8c52ff] from-25% to-[#ff914d] to-75% lg:rounded-lg">
          <Link
            href={liveShow?.bannerLink ?? ""}
            target="_blank"
            className="flex items-center justify-center"
          >
            <div
              className={`whitespace-wrap text-right text-sm font-extrabold leading-[16px] text-white lg:w-auto ${isHomepage ? "lg:text-[32px]" : "lg:text-[28px]"}`}
            >
              <span>{liveShow?.showName ?? ""}</span>
            </div>
            <Image
              src={liveShow?.banner[0] ?? ""}
              alt="Live Stream Banner"
              width={275}
              height={30}
              className={`w-[34%] lg:h-[63px] lg:w-auto`}
            />
          </Link>
        </div>
      )}
    </>
  );
};

export default Ticker;
